
/* Layout */

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 60px;
  width: 60px;
  background: rgba(255, 255, 255, 0.0);
  /* background-image: url("./logo.png"); */
  background-size: cover;
  background-position: center;
  margin: 16px;
  margin-left: 20%;
}

.div_welcome_user {
  width: 440px;
  /* background-color: rgba(255, 55, 255, 1.0); */
  margin: 0 auto;
  float: right;
}

.myheader {
  background: white;
  padding: 0;
  height: 120px;
};

/* ant-Design modificatin */

/* Space between button */
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

.ant-col-16 {
  padding-right: 25px;
}

.callhandler {
  padding: 8px;
  background-color:#f0f2f5;
  text-align: right;
  font-size: 16px;
  color: #878a8d;
};

.ant-input-group-addon {
  background-color: rgba(255, 255, 255, 0);;
}

.floating-box {
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
}

.answer-button-active {
  background-color:#73d13d;
  color: #FFFFFF;
}

.sip-debug-message {
  font-size: 10px;
}

.note-item {
  font-size: 15px;
  color: #878a8d;
  font-style: italic;
}