
.login-form-row{
    margin-top: 5%;
}

.login-form {
    margin: 20px;

}

.div-login-form {
    background: #fff;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow:zz
}

.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}
